li{

}

.navbar-container{
    width: 100%;
    height: 150px;
    background-color: black;
    display: flex;
    justify-content: flex-start;
    position: absolute;
    top: 0%;
    background-color: transparent;
    max-width: 1180px;
}

.navbar-items a{
    list-style: none;
    text-decoration: none;
    text-decoration: none;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}




.navbar-items{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    top: -100%;
    transition: all ease;
}


.navbar-item{
    color: white;
    font-size: 20px;
    letter-spacing: 7px;
    font-weight: 100;


}


.navbar-item:hover{
    cursor: pointer;
    color: white;
}

li{
    list-style-type: none;
}

.menu-icon{
   opacity: 0;
}

.fa-times:before{
    color: white;
    font-size: 40px;
}

.fa-bars:before{
    color: white;
    font-size: 40px;
}

.logo-img-wrapper{
    width: 120px;
    height: 200px;
    overflow: hidden;
    position: relative;
    top: 10%;
}

.logo-img{
    width: 100%;
    height: auto;
}

.logo-img-wrapper-mobile-bg{
    display: none;
}

.logo-img-wrapper-mobile{
    display: none;
    width: 110px;
    height: 125px;
    overflow: hidden;
    position: relative;
    bottom: 0;
}

.link-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-link{
    color: white;
}





@media screen and (max-width: 960px) {
    .navbar-items li{
        list-style: none;
        text-decoration: none;
        text-decoration: none;
    }
    .navbar-link{
        color: white;
        font-size: 25px;
    }
    
    .logo-img-wrapper{
        display: none;        
    }

    .logo-img-wrapper-mobile-bg{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .logo-img-wrapper-mobile{
        overflow: hidden;
        bottom: 0;
        opacity: 1;
        position: absolute;
        display: inline;
        top: 0;
    }

    .logo-img{
        width: 100%;
        height: 100%;
    }

    .navbar-item{
        display: none;
    }

    .menu-icon{
        position: absolute;
        padding-right: 15px;
        right: 0;
        top: 20%;
        font-size: 55px;
        color: #242424;
        opacity: 1;
    }


    .navbar-items{
      display: none;
    }

    .navbar-items.active .navbar-item{
      display: flex;
      backdrop-filter: blur(10px);
      color: white;
      border-bottom: 2px solid;
      width: 100%;
      justify-content: center;
      height: 50%;
      align-items: center;
    }

    .navbar-items.active{
        position: absolute;
        background-color: transparent;
        width: 100%;
        height: 60vh;
        z-index: 1;
        top: 100%;
        display:flex;
        flex-direction: column;
        padding: 0;
        transition: all 0.5s ease;
    }

   


    .navbar-container{
        height: 110px;
    }

    
}