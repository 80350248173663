.modal-bgcontainer{
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    padding-top: 100px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s;
    overflow: auto;
    transition: all 0.3s linear;
    backdrop-filter: blur(8px);

    
}

.modal-buttons-container{
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    justify-content: center;

}

.modal-container{
    top: 5%;
    width: 45%;
    position: relative;
    height: 100%;
    z-index: 10;
    background-repeat: no-repeat;
    background-size: cover;
    border: 3px solid white;
    overflow: hidden;

}


.modal-img-wrapper{
    width: 100%;
    height: 100%;
}


.modal-img{
    width: 100%;
    height: auto;
}

