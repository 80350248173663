.page-container{
    display: flex;
    flex-direction: column;
}

.intro-container{
    background-image: url('../assets/image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 70%;
    height: 100vh;
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.intro-contents{
    position: relative;
    top: 15%;
    width: 100%;
}

.logo-title{
    color:white;
    padding-bottom: 5%;
    font-size: 150px;
    letter-spacing: 19px;
    font-weight: 100;
    text-align: center;

}


.social-links-container{
    margin-bottom: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}



.social-links-container{
    margin-bottom: 8%;
}

.twitter-button-container{
    width: 400px;
    height: 70px;
    padding-bottom: 1%;
}

.discord-button-container{
    width: 400px;
    height: 70px;
}

/* teams section */

.teams-container{
    width: 100%;
    height: 100%;
}

.teams-title{
    padding-top: 5%;
    text-align: center;
    font-size: 70px;
    letter-spacing: 10px;
    font-weight: 100;
    color: white;
}

.who-para{
    color: white;
    font-family: 'Courier New', Courier, monospace;
    text-align: center;
    padding-top: 3%;
    max-width: 700px;
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
}

.teams-container{
    width: 100%;
    height: 100%;
    background-color: black;
    padding-bottom: 10%;
    position: relative;

}

.team-pics-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-pic{
    width: 100%;
    height: auto;
    border: 2px solid black;
    transform: scale(1.2);
}

.team-title{
    color:#C99242;

  
}

.team-pic-wrapper{
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #ffff ;
    margin-bottom: 4%;
    position: relative;
    cursor: pointer;
}


.team-pic-wrapper-wrapper{
    height: 200px;
    height: 200px;
}



.team-pic-wrapper:hover .team-pic{
    box-shadow: 0 1px 6px rgba(0, 0, 0, 1000);
    background-color: rgba(0,0,0, 0.4);
    z-index: 1900;
    filter: blur(10px);
} 

.team-pic-wrapper:hover .twitter-link{
    z-index: 100;
}


.team-pic-list{
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.team-pic-container{
    margin: 2%;
    border-radius: 50%;
    border: 2px solid black;
    margin-top: 5%;
}

.team-title{
    letter-spacing: 7px;
    font-weight: 100;
    text-align: center;
    color: red;
}

.team-name{
    color: white;
    z-index: 1999;
    letter-spacing: 7px;
    font-weight: 100;
    text-align: center;
}

.fa-twitter:before {
    color: white;
    z-index: 100;
}

.team-info-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
}


.fa-twitter:before{
    font-size: 50px;
}
        


.team-title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-title-info{
    padding-top: 2%;
}

.team-para{
    color: rgb(199, 194, 194);
    font-weight: 100;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 2%;
    text-align: center;
    max-width: 500px;
    font-size: 14px;
}

.twitter-wrapper{
    height: 100px;
    height: 100px;
    position: relative;
}


.twitter-link{
    position: absolute;
    z-index: -1 ;
    width: 100px;
    height: 100px;
    bottom: 10%;
    left: 40%;
}



.vcu-text{
    font-weight: 100;
    color: #F4BD41;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 2%;
    text-align: center;
    font-weight: 900;
    max-width: 500px;
    font-size: 14px;
    text-decoration: underline;
} 

.vcu-link{
    font-weight: 100;
    color: #F4BD41;
    font-family: 'Courier New', Courier, monospace;
    padding-top: 2%;
    text-align: center;
    font-weight: 900;
    max-width: 500px;
    font-size: 14px;
    text-decoration: underline;
}


/* ROADMAP */

.road-conatiner{
    position: relative;
    overflow: hidden;
}

.road-conatiner:hover{
    content: " ";
    height: 100%;
    width: 100%;
}


.road-conatiner .bg{
    position: fixed;
    background: url(../assets/roadbg.jpg);
    background-size: cover;
    height: 100%;
    z-index: -1;
    top: 0;
    width: 100vw;

}

.road-conatiner .blur{
    background: rgba(0,0,0,.2);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    height: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.roadmap{
    width: 100%; 
    height: 80%;
}

.roadmap-container{
    margin: 0 auto;
    width: 95%;
    position: relative;
    height: 100%;
}

.roadmap-img-wrapper{
    padding-top: 5%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10%;
}

.roadmap-img{
    width: 80%;
    height: auto;
}

.roadmap-title{
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    color: red;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 16%;
    left: 0;
    right: 32%;
    text-align: center;
    font-size: 35px;
}


.title{
    display: block;
    font-family: 'Courier New', Courier, monospace;
    font-size: 30px;
    color: red;
    font-weight: 900;
    padding-bottom: 3%;
}

.roadmap-detail{
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 22%;
    left: 0;
    right: 35%;
    text-align: center;
    font-size: 20px;
    max-width: 500px;

}



.roadmap-detail2{
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 45%;
    left: 42%;
    right: 0;
    text-align: center;
    font-size: 20px;
    max-width: 500px;


}

.roadmap-detail3{
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 25%;
    left: 0;
    right: 35%;
    text-align: center;
    font-size: 20px;
    max-width: 500px;

}


.roadmap-detail4{
    position: absolute;
    font-family: 'Courier New', Courier, monospace;
    color: white;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    bottom: 1%;
    left: 40%;
    right: 0;
    text-align: center;
    font-size: 20px;
    max-width: 500px;

}


/* lore container */

.lore-container{
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
}


.lore-overview{
    color: white;
    font-family: 'Courier New', Courier, monospace;
    max-width: 800px;
    width: 100%;
    text-align: center;
    padding-bottom: 5%;
    padding-top: 5%;
}


.manga-section-container{
    padding-top: 10%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
}

.manga-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1180px;
    position: relative;
    overflow: hidden;
}

.merch-img-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1180px;
    position: relative;
    overflow: hidden;
}


/* FAQ SECTION */


.faq-bg{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: black;
}

.faqs-container{
    padding-top: 10%;
    background-color: black;
    width: 100%;
    max-width: 860px;
    padding: 25px;
    margin-bottom: 10%;
}


@media screen and (max-width: 1100px)  {
    .manga-img-container{
        flex-direction: column;
    }
    .merch-img-container{
        flex-direction: column;
    }
}

@media screen and (max-width: 850px)  {
    .lore-overview{
        width: 95%; 
    }
    .who-para{
        width: 95%;
    }
 
}

@media screen and (max-width: 700px)  {
    .logo-title{
        font-size: 150px;
        letter-spacing: 15px;
        text-align: center;
        overflow: hidden;
        padding-bottom: 15%;
    }
}

@media screen and (max-width: 610px)  {
    .logo-title{
        font-size: 130px;
        letter-spacing: 15px;
        text-align: center;
        overflow: hidden;
        padding-bottom: 15%;
    }


    .intro-contents{
        top: 5%;
    }
}

@media screen and (max-width: 540px)  {
    .roadmap-img{
        width: 110%;
    }
    .logo-title{
        font-size: 110px;
        letter-spacing: 15px;
        text-align: center;
        overflow: hidden;
        padding-bottom: 15%;
    }
}

@media screen and (max-width: 479px)  {
    .logo-title{
        font-size: 100px;
        letter-spacing: 15px;
        text-align: center;
        overflow: hidden;
        padding-bottom: 15%;
    }
}

@media screen and (max-width: 425px)  {
    .logo-title{
        font-size: 100px;
        letter-spacing: 12px;
        text-align: center;
        overflow: hidden;
        padding-bottom: 15%;
    }
}

@media screen and (max-width: 325px)  {
    .logo-title{
        font-size: 70px;
        letter-spacing: 10px;
        text-align: center;
        overflow: hidden;
        padding-bottom: 15%;
    }
}

@media screen and (max-width: 300px)  {
    .logo-title{
        font-size: 70px;
        letter-spacing: 8px;
        text-align: center;
        overflow: hidden;
        padding-bottom: 15%;
    }
}


@media screen and (max-width: 500px)  {
   .twitter-button-container{
       width: 100%;

   }
    
}


@media screen and (max-width: 450px)  {
    .team-pic-list{
        padding: 0;
    }

    .social-links-container{
        gap: 10px;
    }

    .twitter-button-container{
        width: 80%;
        height: 60px;
    }
    
}






