.button-container{
    width: 100%;
    height: 100%;
    margin-bottom: 2%;
    outline: none;
}


.btn{    
    border-radius: 30px;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    color: white;
    border: 1px solid white;
    font-size: 20px;
    text-align: center;
    font-weight: 800;
    font-family: "AssistantRegular";
    cursor: pointer;
    letter-spacing: 10px;
    font-weight: 100;
}

.btn:hover{
    background-color: white;
    color: black;
}


@media  screen and (max-width: 860px) {

    .bnt{
        font-size: 10px;
    }
}