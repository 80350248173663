.title-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: black;
}

.divider{
    width: 100px;
    height: 10px;
    color: white;
    margin-bottom: 5%;
    border-bottom: 5px solid red;
}

.header-title{
    padding-top: 5%;
    text-align: center;
    font-size: 70px;
    letter-spacing: 10px;
    font-weight: 100;
    color: white;
    padding-bottom: 5%;
    background-color: black;
}