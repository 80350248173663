.footer-container{
    border-top: 1px solid rgb(27, 27, 27);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    overflow: hidden;
    height: 100%;
}

.footer-contents{
    display: flex;
    width: 350px;
    align-items: center;
    justify-content: space-between;
}

.footer-logo-img-wrapper{
    width: 100px;
    height: 100px;
    position: relative;
    margin-bottom: 5%;
}

.fa-twitter:before{
    font-size: 30px;
    color: white;
}

.fa-discord:before {
    content: "\f392";
    font-size: 30px;
    color: white;
}

.footer-logo-img{
    width: 100%;
    height: auto;
}

@media screen and (max-width: 368px) {

    .footer-contents{
        justify-content: space-around;
    }
    
}