*{
  margin: 0;
  font-family: "AssistantRegular";
}
html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}