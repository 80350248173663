.merch-container-bg{
    width: 350px;
    height: 100%;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5%;
    padding-top: 5%;
}


.merch-img-container-outter{
    background-color: gray;
    width: 350px;
    height: 325px;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;

}

.merch-img{
    width: 100%;
    height: 100%;

}

.merch-container-img{


}

.merch-title{
    padding-top: 2%;
    font-size: 13px;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    text-align: center;
}

.merch-name{
    color: white;
    font-size: 12px;
    padding-top: 1%;
    font-family: 'Courier New', Courier, monospace;
    width: 100%;
    text-align: center;
}

@media screen and (max-width: 400px)  {
    .merch-img-container-outter{
        width: 85%;
        height: 100%;    
    }
}

