@font-face {
  font-family: "AssistantRegular";
  src: local("AssistantRegular"),
    url("./fonts/CrucifixionRegular-xKd8\ \(1\).ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gideon Roman', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
