.manga-img-container-outter{
    width: 250px;
    height: 400px;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;

}

.manga-img{
    width: 100%;
    height: 100%;

}

.modal-container-img{
    width: 100%;
    height: auto;
}

@media screen and (max-width: 400px)  {
    .manga-img-container-outter{
        width: 85%;
        height: 100%;    
    }
    
    .modal-container-img{
        height: 100%;
    }
}