.faqs-container .faq{
   margin: 15px;
   padding: 15px;
   background-color: white;
   border-radius: 8px;
   box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.faq .faq-question{
    font-family: 'Courier New', Courier, monospace;
    position: relative;
    font-size: 20px;
    padding-right: 80px;

    transition: all 0.4s ease;

}

.faq-question::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;

    background-image: url('../assets/arrow.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    transition: all 0.4s ease;
}



.faq-answer{
     font-family: 'Courier New', Courier, monospace;
     opacity: 0;
     max-height: 0px;
     overflow-y: hidden;
     transition: all 0.4s ease;
}

.faqopen .faq-question::after{
    transform: rotateY(180);
}

.faqopen .faq-question{
    margin-bottom: 15px;
}

.faqopen .faq-answer{
    opacity: 1;
    max-height: 50px;
}

.faqopen .faq-question::after{
    transform: translateY(-50%) rotate(180deg);
}


@media  screen and (max-width: 570px) {
    .faqs-container .faq .faq-question{
        font-size: 12px;
        letter-spacing: 0;
    }

    .faqs-container .faq .faq-answer{
        font-size: 10px;
    }
    
}

@media  screen and (max-width: 425px) {
    .faqs-container .faq .faq-question{
        font-size: 12px;
        letter-spacing: 0;
    }

    .faqs-container .faq .faq-answer{
        font-size: 10px;
    }
    
}